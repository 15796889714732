<template>
  <div class="car-not-found">
    <v-data-table
      :headers="headers"
      :items="carNotFoundList"
      :page.sync="pagination.page"
      :items-per-page="pagination.limit"
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:item="row">
        <tr v-if="row.item.id != null">
          <td>{{ row.item.carMakeModel }}</td>
          <td>{{ row.item.mobileNumber }}</td>
          <td>{{ convertDate(row.item.createTime) }}</td>
          <td>{{ row.item.contacted ? "Contacted" : "Not contacted" }}</td>
          <td>
            <v-btn
              text
              color="primary"
              @click="toggleStatus(row.item.id, row.item.contacted)"
            >
              Set status
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-pagination
      v-model="pagination.page"
      :circle="true"
      :disabled="false"
      :length="pagination.noOfPage"
      :page="pagination.page"
      update:page
      :total-visible="10000"
      @input="paginationChangeHandler"
    ></v-pagination>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import moment from "moment";

import getAxios from "../../../services/axios-get";
import putAxios from "../../../services/axios-put";

Vue.use(VueAxios, axios, moment);

export default {
  data() {
    return {
      carNotFoundList: [],
      pageCount: 0,
      pagination: {
        limit: 10,
        noOfPage: 1,
        page: 1,
      },
      headers: [
        { text: "Car Make/Model", value: "carMakeModel" },
        { text: "Mobile Number", value: "mobileNumber" },
        { text: "Create Time", value: "createTime" },
        { text: "Status", value: "contacted" },
        { text: "", value: "" },
      ],
      status: "",
    };
  },
  computed: {},
  mounted() {
    this.fetchCredentials();
    this.fetchCarNotFoundList();
  },
  methods: {
    convertDate(date) {
      var parsedDate = moment
        .utc(date)
        .local()
        .format("dddd, MMMM Do YYYY, h:mm:ss A");
      return parsedDate;
    },
    paginationChangeHandler(pagination) {
      this.pagination.page = pagination;
      this.fetchCarNotFoundList();
    },
    fetchCarNotFoundList() {
      // ${process.env.VUE_APP_SERVER_URL}/
      getAxios(`${process.env.VUE_APP_SERVER_URL}/notFound/carMakeModelInfo`)
        .then(res => {
          if (res.data) {
            this.carNotFoundList = res.data.data.content;
            console.log(this.carNotFoundList);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    showAlert(type, message) {
      this.type = type;
      this.alertMsg = message;
      let timer = this.showAlert.timer;
      if (timer) {
        clearTimeout(timer);
      }
      this.showAlert.timer = setTimeout(() => {
        this.type = null;
      }, 3000);
      let t = this.showAlert.t;
      if (t) {
        clearInterval(t);
      }
    },
    fetchCredentials() {
      var self = this;
      const token = localStorage.getItem("jwt");
      if (token) {
        self.token = token;
      } else {
        self.showAlert("error", "unauthorized..");
      }
    },
    toggleStatus(id, contacted) {
      putAxios(`${process.env.VUE_APP_SERVER_URL}/notFound/toggleStatus`, {
        id,
        contacted,
      })
        .then(() => {
          this.fetchCarNotFoundList();
        })
        .catch(err => {
          console.error(err);
        });
    },
  },
};
</script>
